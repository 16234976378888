<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getObavestenja)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/obavestenja/create')" v-if="$utils('roleUser', 'Obavestenja', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table
					v-if="obavestenja.length"
					:data="obavestenja"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getObavestenja)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="center"
						width="120"
						prop="slika_url">
					<template slot-scope="scope">
						<el-image
								v-if="scope.row.slika_url > ''"
								style="width: 100px; height: 100px"
								:src="scope.row.slika_url"
								fit="['fill', 'contain', 'cover', 'none', 'scale-down']">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="naslov"
						:label="$t('obavestenja.naslov')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="left"
						prop="tekst"
						:label="$t('obavestenja.tekst')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="left"
						prop="datum_objave"
						:label="$t('obavestenja.datumObjave')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.datum_objave)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="aktivno"
						width="120"
						:label="$t('obavestenja.aktivno')"
						sortable="custom">
					<template slot-scope="scope">
						<el-button size="mini" @click="statusUpdate(scope.row.id)">
							<i v-if="scope.row.aktivno === 'NE'" class="fas fa-times-circle color-text--red"></i>
							<i v-else-if="scope.row.aktivno === 'DA'" class="fas fa-check-circle color-text--green"></i>
						</el-button>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						width="180">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.pregled')" placement="top" v-if="$utils('roleUser', 'Obavestenja', 'edit')">
							<el-button type="warning" icon="fas fa-eye" @click="$utils('goTo', '/obavestenja/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('obavestenja.objavi')" placement="top" v-if="$utils('roleUser', 'Obavestenja', 'edit')">
							<el-button icon="el-icon-s-promotion" @click="posaljiFirebase(scope.row.id)" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Obavestenja', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('obavestenja', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getObavestenja" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'obavestenja-list',
	data() {
		return {
			obavestenja: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naslov',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('obavestenja.obavestenja')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getObavestenja()]);
	},
	methods: {
		async getObavestenja() {
			this.gridLoad = true;
			return this.$get('obavestenja', this.$utils('getParams')).then(data => {
				this.obavestenja = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		},
		async posaljiFirebase(id) {
			let data = JSON.stringify({ id_obavestenja: id });
			this.$confirm(this.$t('obavestenja.objaviPoruka'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('obavestenja.objavi'),
				cancelButtonText: this.$t('confirm.odustani'),
				type: 'warning'
			}).then(() => {
				let sh = this.$notify.info({
					title: this.$t('obavestenja.info'),
					message: this.$t('obavestenja.objavaSalje'),
					showClose: false,
					position: 'bottom-right',
					duration: 0
				});
				this.$api.firebase.save(data).then(() => {
					sh.close();
					this.$notify({
						title: this.$t('obavestenja.info'),
						message: this.$t('obavestenja.objavaGotova'),
						type: 'success'
					});
				});
			});
		},
		statusUpdate(id) {
			this.$confirm(this.$t('confirm.opisPromeniStatus'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$put('obavestenjaStatus', { id: id }, {}).then(() => {
					this.getObavestenja();
				}).catch(() => {
					this.gridLoad = false;
				});
			});
		}
	}
};
</script>

<style>
	.image-slot {
		padding: 40px 0 0 0;
	}
	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ellipsis div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fcf;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.lds-ellipsis div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
</style>
